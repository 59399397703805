import { forwardRef } from 'react';

import { buttonClassNames, ButtonVariants, classNames } from '@/utils/class-names';
import { allPagePaths, DynamicPagePath, PagePath } from '@/utils/pages';
import { reportSentryError, reportSentryWarning } from '@/utils/sentry';
import { includes } from '@/utils/typescript';
import { omit } from 'lodash-es';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { resolveRoute } from 'vike/routing';

type SharedLinkProps = {
  asButton?: ButtonVariants;
  disabled?: boolean;
  download?: string;
  target?: '_blank';
  className?: string;
  onClick?: () => void;
  title?: string;
  children: React.ReactNode;
};

// Enforce type safety when linking to internal pages
type InternalLinkProps = SharedLinkProps & {
  to: PagePath | DynamicPagePath | `${PagePath}?${string}` | `${PagePath}#${string}`;
};

// Escape hatch for links to external sites, static assets, etc.
type ExternalLinkProps = SharedLinkProps & {
  href:
    | `https:${string}`
    | `http:${string}`
    | `/assets/${string}`
    | `mailto:${string}`
    | `tel:${string}`
    | `#${string}`;
};

export const Link = forwardRef<HTMLAnchorElement, InternalLinkProps | ExternalLinkProps>(
  (props, ref) => {
    const { asButton, disabled = false, className, children } = props;

    const allClassNames = classNames(
      asButton ? 'inline-block' : '',
      asButton ? buttonClassNames[asButton] : 'text-blue-600 underline',
      disabled ? 'opacity-75 pointer-events-none' : '',
      className
    );

    if ('to' in props) {
      // When "to" is used instead of "href", we validate the internal link
      flagInvalidInternalPageLinks(props.to);
      // @ts-expect-error Convert to -> href before rendering
      props = { ...omit(props, 'to'), href: props.to };
    }

    return (
      <a {...omit(props, 'asButton')} className={allClassNames} ref={ref}>
        {children}
      </a>
    );
  }
);

// Report links to internal pages that aren't listed in breadcrumbs or that are missing trailing slash
function flagInvalidInternalPageLinks(href: string) {
  const isInternalPage = href.startsWith('/') && !(href.split('/').pop() || '').includes('.');
  if (!isInternalPage) return;

  const pagePath = href.split(/[#?]/)[0];

  if (!pagePath.endsWith('/')) {
    // Trailing slash is enforced by Netlify for consistency & SEO (Pretty URL option), so we should
    // avoid linking to pages w/out trailing slash as it'll result in a 301 redirect
    reportSentryWarning(`Internal URL missing trailing slash: ${pagePath}`, { href });
  }

  // Find route matching internal link
  if (includes(allPagePaths, pagePath) || includes(allPagePaths, pagePath + '/')) return;

  // Handle dynamic route segments
  for (const route of allPagePaths) {
    if (route.includes('@') && resolveRoute(route, pagePath).match) return;
  }

  reportSentryError(`Link to invalid internal page path: ${pagePath}`, { href });
}

export function EmailLink({ email, className }: { email: string; className?: string }) {
  if (isEmail(email)) {
    return (
      <Link href={`mailto:${email}`} target="_blank" className={className}>
        {email}
      </Link>
    );
  }
  return <>{email}</>;
}

export function PhoneLink({ phone, className }: { phone: string; className?: string }) {
  if (isMobilePhone(phone)) {
    return (
      <Link href={`tel:${phone}`} className={className}>
        {phone}
      </Link>
    );
  }
  return <>{phone}</>;
}
