import { Notification, NotificationProps } from '@/components/notification';
import { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import toast, { ToastPosition, useToasterStore } from 'react-hot-toast';
import waitForTimers from 'wait-for-timers';
import { onClientHydrationComplete } from './hydration';
import { reportSentryError } from './sentry';

export type ShowNotificationParams = Omit<NotificationProps, 'onDismiss'> & {
  position?: ToastPosition;
  duration?: number; // Defaults to 2000 for success, 4000 for error, infinite for loading
  uniqueId?: string; // To avoid duplicates
  onManuallyDismiss?: () => void;
};

// Workaround for issue where, if toast() is called too soon, it doesn't actually show up :(
const onReadyToShowToast = new Promise(
  (resolve) => onClientHydrationComplete(() => setTimeout(resolve, 20)) // 20ms after hydration, tested in all desktop browsers (Safari is most finicky)
);

// Show a toast notification to user
export function showNotification(props: ShowNotificationParams) {
  console.log('showNotification()', { heading: props.heading, message: props.message });

  // Handle case where toast is immediately dismissed
  let wasDismissedBeforeRender = false;
  let _dismissToast = () => {
    wasDismissedBeforeRender = true;
  };

  // Unfortunately, we can't call toast() until the page is hydrated
  onReadyToShowToast.then(() => {
    if (wasDismissedBeforeRender) return;

    // Show Notification toast using React Hot Toast
    const toastId = toast(
      <Notification
        {...props}
        onDismiss={() => {
          _dismissToast();
          if (props.onManuallyDismiss) props.onManuallyDismiss();
        }}
      >
        {props.children}
      </Notification>,
      {
        className:
          'overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 w-96 max-w-sm',
        position: props.position || 'top-right',
        duration: props.duration,
        id: props.uniqueId,
      }
    );

    // Let caller dismiss toast
    _dismissToast = () => {
      toast.dismiss(toastId);
    };
  });

  return function dismissToast() {
    _dismissToast();
  };
}

// Very hacky way to get notification ids by temporarily rendering component to use hook, but it works
export function getNotificationIds(): Promise<string[]> {
  return new Promise((resolve) => {
    // Should never take longer than a few ms unless tab is hidden
    const cancelTimeout = waitForTimers([document.visibilityState === 'visible' ? 10 : 100], () => {
      reportSentryError('getNotificationIds render too slow');
      resolve([]);
    });

    // Temporarily render component to call custom hook & get toast ids
    const root = ReactDOM.createRoot(document.createElement('div'));
    root.render(
      <ToasterStoreComponent
        setIds={(ids: string[]) => {
          cancelTimeout();
          resolve(ids);
        }}
      />
    );
  });
}

// Only used to call custom hook, doesn't render anything
function ToasterStoreComponent({ setIds }: { setIds: (result: string[]) => void }) {
  const { toasts } = useToasterStore();
  setIds(toasts.map((t) => t.id));
  return <Fragment />;
}

// if (typeof window !== 'undefined') {
//   // @ts-ignore
//   window.getNotificationIds = getNotificationIds;

//   // setTimeout(() => {
//   //   showNotification({
//   //     heading: 'Successfully connected!',
//   //     duration: 1500,
//   //   });
//   // }, 1000);
// }
