import React, { ButtonHTMLAttributes, forwardRef, MouseEvent, useEffect, useState } from 'react';

import { buttonClassNames, ButtonVariants, classNames } from '@/utils/class-names';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import waitForTimers from 'wait-for-timers';

export type ButtonProps = {
  variant: ButtonVariants;
  children: React.ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  visiblyDisabled?: boolean;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onVisiblyDisabledClick?: () => void;
  title?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      type = 'button',
      disabled = false,
      visiblyDisabled = false,
      children,
      className,
      onClick,
      onVisiblyDisabledClick,
      title,
    },
    ref
  ) => {
    const allClassNames = classNames(
      buttonClassNames[variant],
      disabled || visiblyDisabled ? 'opacity-75 cursor-not-allowed' : '',
      className
    );

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      // Should never happen (disabled buttons aren't clickable)
      if (disabled) {
        // Prevent form submission
        event.preventDefault();
        return;
      }

      // Handle visibly disabled button click
      if (visiblyDisabled) {
        // Prevent form submission
        event.preventDefault();
        // When visibly disabled button is clicked, tell user why they can't click this button
        if (onVisiblyDisabledClick) onVisiblyDisabledClick();
        return;
      }

      // Normal click
      if (onClick) onClick(event);
    };

    return (
      <button
        className={allClassNames}
        type={type}
        disabled={disabled}
        onClick={handleClick}
        ref={ref}
        title={title}
      >
        {children}
      </button>
    );
  }
);

export function CopyTextButton({
  displayText,
  copyText,
  title = copyText,
}: {
  displayText: string;
  copyText: string;
  title: string;
}) {
  const [hasCopied, setCopied] = useState(false);
  const onCopySortKey = () => {
    navigator.clipboard.writeText(copyText);
    setCopied(true);
  };
  useEffect(() => {
    if (hasCopied) return waitForTimers([1200], () => setCopied(false));
  }, [hasCopied]);
  return (
    <Button variant="bold-text" title={title} onClick={onCopySortKey} className="font-medium">
      {hasCopied ? (
        'Copied to Clipboard!'
      ) : (
        <>
          {displayText} <DocumentDuplicateIcon className="-mt-1 inline-block h-4 w-4" />
        </>
      )}
    </Button>
  );
}
