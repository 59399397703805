import { fetchUserSessionOrTimeout } from '@/auth/user-store';
import { isEmpty } from 'lodash-es';
import { debounce as debounceAsync } from 'perfect-debounce';
import { setupRequestApi } from './request-api';
import { reportSentryError, reportSentryWarning } from './sentry';
export { RequestError } from './request-api';

// Util for making API requests, validating responses, and reporting any unexpected issues in Sentry
export const requestApi = setupRequestApi({
  reportIssue: (level, error, details) => {
    // Prevent Sentry from grouping these errors by stack trace (group by message instead so that
    // different API endpoint errors don't get lumped together into the same issue)
    const fingerprint = ['{{ default }}', error.message];
    if (level === 'error') {
      reportSentryError(error, details, fingerprint);
    } else {
      reportSentryWarning(error, details, fingerprint);
    }
  },
});

export function urlWithParams<T extends string>(
  baseUrl: T,
  paramsObject: Record<string, string | number>
) {
  // Avoid adding `?` if there's no parameters to add
  if (isEmpty(paramsObject)) return baseUrl;

  // @ts-expect-error - it's fine if we pass a number and let it be coerced to a string
  const queryParamsString = new URLSearchParams(paramsObject).toString();
  return `${baseUrl}?${queryParamsString}` as `${T}?${string}`;
}

export async function getAuthHeader() {
  return {
    Authorization: `Bearer ${await getAuthToken()}`,
  };
}

export const getAuthToken = debounceAsync(
  async () => {
    let session;
    try {
      session = await fetchUserSessionOrTimeout();
      return session.tokens!.idToken!.toString();
    } catch (error) {
      // TEMP: debug failure to get session and figure out what best to do in this scenario
      console.warn('Failed to get auth token', {
        session,
        error,
      });
      throw new Error('Not signed-in');
    }
  },
  // No need to wait between updates, the goal is to prevent overlapping async calls from happening
  // e.g. to avoid refreshing JWT token 4 times if it's expired & SWR fires off 4 API requests when
  // user re-enters tab.
  0,
  { leading: true }
);

// Used for simulating latency for local debugging
export function wait(timeoutMs: number) {
  return new Promise((res) => setTimeout(res, timeoutMs));
}
