import { objectKeys } from './typescript';

//
// All pages
//

export const allPages = {
  '/': 'Customer Portal',
  // Redwood Materials employees
  '/dashboard/': 'Redwood Dashboard',
  '/dashboard/shipments/': 'Shipments',
  '/dashboard/redwood-users/': 'Redwood Users',
  '/dashboard/supplier-users/': 'Supplier Users',
  '/dashboard/spot-feedstock/': 'Spot Feedstock Pricing',
  '/dashboard/spot-feedstock/offer/': 'New Offer',
  '/dashboard/spot-feedstock/offer/@offer-id/': 'Update Offer',
  '/dashboard/spot-feedstock/config/': 'Admin Configuration',
  '/dealership-demo/': 'Dealership Network Demo',
  // VW dealers & corporate users
  '/vwgoa/': 'VWGoA Dashboard',
  '/vwgoa/recycling-requests/': 'Recycling Requests',
  // BMW dealers & corporate users
  '/bmw/': 'BMW Battery Recycling',
  // Porsche dealers & corporate users
  '/porsche/': 'Porsche Battery Recycling',
  // Suppliers
  '/suppliers/': 'Supplier Dashboard',
  '/suppliers/shipment-request/': 'New Shipment Request',
  '/supplier-onboarding/': 'Supplier Onboarding',
  '/supplier-onboarding/europe/': 'Europe',
  // Ultium (supplier)
  '/ultium/': 'Ultium Dashboard',
  '/ultium/shipment-request/': 'New Shipment Request',
  '/ultium/pre-booking-request/': 'New Pre-Booking Request',
  '/ultium/add-handling-units/': 'Confirm ASN for Pre-Booking',
  // Panasonic (supplier)
  '/panasonic/': 'Panasonic Dashboard',
  '/panasonic/shipment-request/': 'New Shipment Request',
  '/panasonic/pre-booking-request/': 'New Pre-Booking Request',
  '/panasonic/add-handling-units/': 'Confirm ASN for Pre-Booking',
  // Auto dismantler portal
  '/sell-my-battery/': 'Sell your EV battery pack',
  '/sell-my-battery/terms-of-use/': 'Terms of Use',
  // Misc
  '/sign-in/': 'Sign In',
} satisfies Record<`/${string}/` | '/', string>;
export type PagePath = keyof typeof allPages;
export type DynamicPagePath = `/dashboard/spot-feedstock/offer/${string}/`;
export const allPagePaths = objectKeys(allPages);
