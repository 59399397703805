import { twMerge } from 'tailwind-merge';

export type ButtonVariants =
  | 'white-outline'
  | 'purple'
  | 'green'
  | 'yellow'
  | 'red'
  | 'bold-text'
  | 'none';

const sharedButtonClassNames =
  'rounded-md py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors ease-out';

export const buttonClassNames: Record<ButtonVariants, string> = {
  'white-outline': `inner-border inner-border-gray-300 bg-white text-gray-700 hover:bg-gray-50 ${sharedButtonClassNames}`,
  purple: `bg-indigo-600 text-white hover:bg-indigo-700 ${sharedButtonClassNames}`,
  green: `bg-green-600 text-white hover:bg-green-700 ${sharedButtonClassNames}`,
  yellow: `inner-border inner-border-gray-300 bg-amber-300 text-gray-800 hover:bg-yellow-400 ${sharedButtonClassNames}`,
  'bold-text': 'font-semibold',
  red: `bg-red-700 text-white hover:bg-red-800 ${sharedButtonClassNames} focus:ring-red-300 `,
  none: '',
};

// Merge classnames, discarding false values
export function classNames(...classes: (string | false | undefined | null)[]) {
  return twMerge(classes.filter(Boolean).join(' '));
}

export const centeredContentClassName = 'mx-auto flex w-full max-w-5xl sm:px-6 lg:px-8';

// Centered column of page content below breadcrumbs
export const pageContentClassName = classNames(centeredContentClassName, 'grow flex-col py-4');

// Little pill-box badges for tags
export const pillBadgeClassNames = {
  red: 'inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800',
  yellow:
    'inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800',
  green:
    'inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800',
  darkgreen:
    'inline-flex rounded-full bg-green-700 px-2 text-xs font-semibold leading-5 text-green-100',
  gray: 'inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-800',
};

export const labelClassName = 'block text-sm font-medium text-gray-700';

export function getInputClassName({
  hasLeadingIcon = false,
  hasTrailingIcon = false,
  hasError = false,
  isDisabled = false,
  extra = '',
}) {
  return classNames(
    'block w-full rounded-md border-0 ring-1 ring-inset ring-gray-300 focus:ring-indigo-500 focus:ring-2 focus:outline-none',
    // On small screens, use 16px font size to avoid iOS zooming in on input focus, but reduce
    // the vertical padding to compensate, so input height doesn't change
    'py-1.5 sm:py-2 sm:text-sm',
    hasLeadingIcon && 'pl-10',
    hasTrailingIcon && 'pr-10',
    hasError && 'ring-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 ',
    isDisabled && 'bg-gray-50',
    extra
  );
}
