import { urlWithParams } from './requests';
import { reportSentryError } from './sentry';

// Add URL parameters after '#' so they are only processed by JavaScript
// and server can ignore them, returning a cached HTML response
export function urlWithHashParams<T extends string>(
  baseUrl: T,
  paramsObject: Record<string, string | number>
) {
  return urlWithParams(baseUrl, paramsObject).replace('?', '#') as `${T}#${string}`;
}

// Parse URL parameters in hash
// Hash is used instead of query since it's entirely client-side
export function getParamInUrlHash(key: string, validate?: (value: string) => boolean) {
  if (typeof window !== 'undefined') {
    const param = new URLSearchParams(location.hash.replace(/^#/, '')).get(key);
    if (param !== null) {
      if (!validate || validate(param)) {
        return param;
      }
      // Report any instances where we stick an invalid value in a URL
      reportSentryError(`Invalid URL parameter in hash: ${key} = ${param}`, {
        hash: location.hash,
      });
    }
  }
  return '';
}

export function cleanupUrlHash() {
  const newRootRelativeUrl = location.pathname + location.search;
  history.replaceState({}, document.title, newRootRelativeUrl);
}
