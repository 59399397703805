import { crossBrowserAbortSignalTimeout } from '@/utils/request-api';
import { baseUrlUnproxied } from '../config';

//
// Check if user may need to connect to VPN to access API
// If so, attempt to get the OpenAPI docs (html). If it times out, assume the user
// is not on the VPN and reject the promise (trigger a UI notification). This is a
// workaround for the unfortunate fact that when non on VPN, requests to APIs will
// hang and never resolve, instead of returning an error status as we'd expect
//

export async function checkVpnConnection() {
  let timeoutMs = 3500;
  try {
    // @ts-expect-error - navigator.connection is not available in all browsers
    timeoutMs = /2g/.test(navigator.connection.effectiveType) ? 5000 : 3000;
  } catch (error) {
    /* empty */
  }

  // Request OpenAPI docs -- just static files in s3 so should respond quickly
  const abortSignal = crossBrowserAbortSignalTimeout(timeoutMs);

  // If we get any response, then user is connected to VPN
  // If request it aborted, then it's likely user is disconnected
  // Unproxied URL is used b/c Netlify proxy can't be used for restricted APIs
  await fetch(`${baseUrlUnproxied}/openapi/`, { signal: abortSignal });
}
