import { reportSentryWarning } from '@/utils/sentry';
import { showNotification } from '@/utils/show-notification';
import { deepEqualWithJsonStringify } from '@/utils/use-deep-equal';
import { UserPool, vwgoaIdpProviders } from './cognito-user-pools';
import { getRedirectRoutes } from './redirect-user-after-oauth-callback';
import { signInVwgoaUserWithSso } from './sign-in-with-sso';
import { UserStoreState } from './user-store';

export function logUserDataChanges(state: UserStoreState, prevState: UserStoreState) {
  if (
    state.auth.status === prevState.auth.status &&
    // @ts-expect-error Typescript complains b/c user property may not exist
    deepEqualWithJsonStringify(state.auth.user, prevState.auth.user)
  )
    return;

  // Log when user data changes
  if (state.auth.status === 'UNAUTHENTICATED') {
    console.log('User is not signed-in');
  } else if (state.auth.status === 'AUTHENTICATED') {
    console.log('User is authenticated', {
      type: state.auth.user.type,
      user: state.auth.user,
      payload: state.auth.payload,
    });
  }
}

export function showSignInFailedNotification(userPool: UserPool, message?: string) {
  // Don't show user this generic error message that happens if they click
  // the Back button in the OAuth flow
  if (message === 'An error occurred while validating the state') return;

  // Improve error message if Redwood SSO user is denied access
  if (
    userPool === 'redwood_okta' &&
    message &&
    message.startsWith('User is not assigned to the client application')
  ) {
    message = `Your Redwood Materials account has not been given access to the "Customer Portal" Okta app. Please contact the IT helpdesk to be given access.`;
  }

  // Handle VW SSO case where corporate user attempts to sign-in via VW Hub or Access Audi. In this case,
  // automatically send them to the corporate SSO provider. Example error message:
  //   "PreTokenGeneration failed with error Corporate and field users must sign in with VW internal SSO.
  //   (Service: AWSCognitoIdentityProviderInternalService; Status Code: 400; Error Code:
  //   UserLambdaValidationException; Request ID: b77bcb49-3b6c-45a1-9e60-2a5301c43520; Proxy: null)"
  if (
    userPool === 'vwgoa_sso' &&
    message &&
    message.startsWith(
      'PreTokenGeneration failed with error Corporate and field users must sign in with VW internal SSO'
    )
  ) {
    return signInVwCorporateUserAutomatically();
  }

  // For VW SSO errors handled by our PreTokenGeneration lambda function, show user the exact Exception message
  if (
    userPool === 'vwgoa_sso' &&
    message &&
    message.startsWith('PreTokenGeneration failed with error ')
  ) {
    message = message
      .replace('PreTokenGeneration failed with error ', '')
      .split('(Service: AWS')[0];
  }

  // Report "Internal server error". Has been indicative of Cognito IDP attribute mapping errors
  if (message && message.toLowerCase().includes('internal server error')) {
    reportSentryWarning(`Sign in failed: ${message}`, { userPool });
  }

  showNotification({
    heading: 'Sign-in failed',
    message,
    type: 'error',
    duration: 7 * 1000, // Give users enough time to read error message
  });
}

// Attempt to automatically sign-in VW Corporate / Field user using VW Corporate SSO
// after they failed to sign in with VW/Audi dealer SSO. This will happen to corporate
// users who are coming from WPP but are required to use a higher security SSO platform
// when possible.
async function signInVwCorporateUserAutomatically() {
  const clearNotification = showNotification({
    heading: `Signing in corporate/field user automatically...`,
    duration: 10 * 1000, // Redirect should happen pretty quick, but just in case...
  });

  // After successful corporate user sign-in, take user back to their intended destination,
  // e.g. a deep link from WPP.
  try {
    const priorRoutes = getRedirectRoutes();
    if (priorRoutes)
      console.log('Re-using prior post-oauth-redirects from prior SSO attempt', priorRoutes);
    signInVwgoaUserWithSso(
      vwgoaIdpProviders.corporate,
      priorRoutes ? priorRoutes.successUrl : location.pathname + location.hash,
      priorRoutes ? priorRoutes.errorUrl : location.pathname
    );
  } catch (error) {
    clearNotification();
    reportSentryWarning('Automatic VW corporate sign-in failed', { cause: error as Error });
  }
}
