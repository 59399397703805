import { requestUserDataRefresh } from '@/auth/refresh-user-data';
import { onClientHydrationComplete } from '@/utils/hydration';
import { exposeOnWindow } from '@/utils/local-development';
import { reportSentryWarning, setSentryUser } from '@/utils/sentry';
import { setupStorage } from '@/utils/storage';
import { includes } from '@/utils/typescript';
import { cleanupUrlHash } from '@/utils/url';
import useDeepEqualWithJsonStringify, { deepEqualWithJsonStringify } from '@/utils/use-deep-equal';
import { signOut as signOutUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import pTimeout from 'p-timeout';
import { debounce as debounceAsync } from 'perfect-debounce';
import waitForTimers from 'wait-for-timers';
import { ZodIssue, z } from 'zod';
import { create } from 'zustand';
import {
  UserPool,
  configureAmplifyUserPool,
  emailPasswordUserPools,
  userPools,
} from './cognito-user-pools';
import { logUserDataChanges, showSignInFailedNotification } from './user-effects';
import {
  CognitoIdTokenPayload,
  UserSchema,
  getUserFromIdToken,
  redwoodUserTypes,
  userConfig,
} from './user-schemas';
import { fetchUserSession } from './user-session';

//
// In LocalStorage, store a reference to the current user's ID and user pool
// This helps us:
// - Reconnect to the same user pool after a page load / refresh / navigation
// - Suggest the user's preferred sign-in method in the UI
// - Detect changes to user state in other tabs, making this tab as stale and
//   and asking user to refresh
//

const storedUserStateSchema = z.object({
  userPool: z.enum(userPools).nullable(),
  userId: z.string().uuid().nullable(),
});
type StoredUserState = z.infer<typeof storedUserStateSchema>;

const userStateLocalStorageKey = 'user_state';
const userStateStore = setupStorage<StoredUserState>(userStateLocalStorageKey, (val) => {
  const validationResult = storedUserStateSchema.safeParse(val);
  return validationResult.success ? validationResult.data : { userPool: null, userId: null };
});

//
// Zustand store for the current signed-in user & their user pool
//

export type UserStoreState = {
  userPool: {
    // User pool from prior session (defaults to "external" for new session)
    name: UserPool;
    // False if user has never attempted to sign-in before. We use this to
    // distinguish between a default user pool being used (e.g. 'external')
    // and the user having signed-in before with the default pool, since it
    // may impact what sign-in method we suggest.
    hasUserChosenPool: boolean;
  };
  auth:
    | { status: 'CONFIGURING' }
    | { status: 'UNAUTHENTICATED' }
    | {
        status: 'AUTHENTICATED';
        // Typed user object exposed to components:
        user: UserSchema;
        // Not exposed to components. Sent to Sentry & logged for debugging:
        payload: CognitoIdTokenPayload; // JWT ID token payload
        validationIssues?: ZodIssue[]; // Zod schema validation issues
      };
  // True if user pool or user ID changes in a different tab
  // We display a dialog and force the user to refresh
  isStale: boolean;
};

const _useUserStore = create<UserStoreState>(() => {
  const { userPool: userPoolFromPriorSession } = userStateStore.getItem();
  return {
    userPool: {
      // Use the same user pool as we did last session
      name: userPoolFromPriorSession || 'external',
      hasUserChosenPool: userPoolFromPriorSession !== null,
    },
    auth: {
      // Wait to set user state until we've checked JWT token (see syncUserDataWithSession)
      status: 'CONFIGURING',
    },
    isStale: false,
  };
});

//
// Expose hooks & utilities to components
//

export const useUser = () =>
  _useUserStore(
    // Only re-render if values actually change (compares old & new with JSON.stringify)
    useDeepEqualWithJsonStringify(({ auth, userPool }) => {
      return {
        isSignedIn: auth.status === 'AUTHENTICATED',
        isLoading: auth.status === 'CONFIGURING',
        user:
          auth.status === 'AUTHENTICATED'
            ? { ...auth.user, ...userConfig[auth.user.type] }
            : undefined,
        userType: auth.status === 'AUTHENTICATED' ? auth.user.type : undefined,
        isRedwoodUser:
          auth.status === 'AUTHENTICATED' ? includes(redwoodUserTypes, auth.user.type) : false,
        signOut: () => {
          // Clear any auth-related parameters from the URL to ensure we don't automatically
          // attempt to sign the user back in (e.g. for VW SSO deep-links)
          cleanupUrlHash();
          // Clear the user's JWT from memory & LocalStorage
          signOutUser().then(() => {
            // Refresh the page to ensure all component state is reset
            // e.g. current /sell-my-battery offer & searches
            // Not necessary for SSO users since they're already redirected
            if (emailPasswordUserPools.includes(userPool.name)) {
              console.log('Reloading page after sign out');
              location.reload();
            }
          });
        },
      };
    })
  );

export const useUserPool = () =>
  _useUserStore(
    // Only re-render if values actually change (compares old & new with JSON.stringify)
    useDeepEqualWithJsonStringify(({ userPool }) => {
      return {
        userPool: userPool.name,
        hasUserChosenPool: userPool.hasUserChosenPool,
      };
    })
  );

export const useIsUserStateStale = () => _useUserStore((s) => s.isStale);

export const setUserPool = (newUserPool: UserPool, shouldReload = true) => {
  // Sanity check: user pool can't be changed while user is signed-in. We should always
  // sign out first
  if (_useUserStore.getState().auth.status === 'AUTHENTICATED') {
    throw new Error('User pool cannot be changed while user is signed-in');
  }
  // Update user pool to match user's preference
  _useUserStore.setState({ userPool: { name: newUserPool, hasUserChosenPool: true } });

  // When user pool changes, refresh the page to ensure Amplify is set up initially to use
  // the new user pool. This is necessary b/c Amplify.configure() isn't foolproof and won't
  // detect if there's an existing user session in the new pool. It also doesn't prevent a
  // prior session (in the old pool) from taking over if a fetch() hasn't completed yet, sigh.
  // Safest thing is to refresh the page to ensure that Amplify is only dealing with a single
  // user pool at a time.
  if (shouldReload) location.reload();
};

//
// Keep user store in sync with user data (from Cognito JWT)
//

/**
 * Update user data (exposed via useUser() hook) to match the Cognito
 * user session (JWT ID token). Called every time the Amplify Hub fires
 * an auth-related event.
 */
export const syncUserDataWithSession = debounceAsync(
  async (trigger: unknown) => {
    let idTokenPayload: CognitoIdTokenPayload;
    try {
      // Get user data from JWT token (user id, attributes, groups, etc.)
      // Note: do NOT pass { forceRefresh: true } or you'll start an infinite loop, as
      // Hub will fire a tokenRefresh event.
      // A stale session is fine here, the goal of this function is merely to have our
      // user model match the session. Token refreshing happens elsewhere.
      const session = await fetchUserSessionOrTimeout();
      idTokenPayload = session.tokens!.idToken!.payload as CognitoIdTokenPayload;
    } catch (error) {
      reportSessionErrorIfUnexpected(error);
      _useUserStore.setState({ auth: { status: 'UNAUTHENTICATED' } });
      return;
    }

    const { user, validationIssues } = getUserFromIdToken(idTokenPayload);
    _useUserStore.setState({
      auth: {
        status: 'AUTHENTICATED',
        user,
        payload: idTokenPayload,
        // Typically empty, but included in case user attributes did not pass Zod schema
        validationIssues,
      },
    });

    // If inital JWT is older than 15 seconds, refresh it
    // Only on initial page load, to allow us to update Cognito and tell user to refresh
    if (trigger === 'pageload' && Date.now() / 1000 - idTokenPayload.iat > 15) {
      requestUserDataRefresh('pageload');
    }
  },
  // No need to wait between updates, the goal is to prevent overlapping async calls from happening
  0,
  { leading: true }
);

// Keep track of most recent event fired by Hub (for reporting unexpected errors below)
let mostRecentHubAuthEvent = { name: '', timestamp: NaN };

// Report unexpected session errors to Sentry
function reportSessionErrorIfUnexpected(error: unknown) {
  if (error instanceof Error) {
    if (error.name === 'TypeError' && /token|idToken/.test(error.message)) {
      // There's no ID Token. Happens if user signed-out to begin with.
      return;
    }
    if (
      error.name === 'TimeoutError' &&
      mostRecentHubAuthEvent.name === 'signInWithRedirect_failure'
    ) {
      // fetchAuthSession() timed out after oauth sign-in failed (an Amplify issue where the returned promise
      // never resolves or rejects, sigh). You can replicate it by revoking a user's Okta access to the
      // app and then attempting to sign-in. Timeout is needed to avoid staying in loading state forever.
      return;
    }
  }
  // Error is novel: report it
  reportSentryWarning(new Error('Failed to get session', { cause: error }), {
    mostRecentHubAuthEvent: {
      ...mostRecentHubAuthEvent,
      ago: Date.now() - mostRecentHubAuthEvent.timestamp,
    },
  });
}

//
// If user state changes in other tabs, set isStale=true and prompt user to refresh
//

if (typeof window !== 'undefined') {
  const onLocalStorageUpdateFromOtherTab = (event: StorageEvent) => {
    // Ignore updates to other LocalStorage keys
    if (event.key && event.key !== userStateLocalStorageKey) return;

    // Ensure that the user pool or ID has actually changed
    const stateFromOtherTab = userStateStore.getItem();
    const stateFromThisTab = getStateToStore(_useUserStore.getState());
    if (deepEqualWithJsonStringify(stateFromOtherTab, stateFromThisTab)) return;

    // If all that changed was the user pool and neither tab is signed-in,
    // then update the user pool since there's a low risk of breaking anything
    if (!stateFromThisTab.userId && !stateFromOtherTab.userId && stateFromOtherTab.userPool) {
      console.log('User pool updated in other tab', {
        stateFromOtherTab,
        stateFromThisTab,
      });
      // Do not reload page in background as that could start a reload war between tabs
      setUserPool(stateFromOtherTab.userPool, false);
    } else {
      // More significant user change(s) occurred. Mark this tab as stale and
      // force user to refresh.
      console.log('User state updated in other tab', {
        stateFromOtherTab,
        stateFromThisTab,
      });
      _useUserStore.setState({ isStale: true });
      window.removeEventListener('storage', onLocalStorageUpdateFromOtherTab);
    }
  };
  window.addEventListener('storage', onLocalStorageUpdateFromOtherTab);
}

//
// Subscribe to user changes
//

// Re-configure Amplify whenever user pool changes
_useUserStore.subscribe((state, prevState) => {
  if (state.userPool.name !== prevState.userPool.name) {
    configureAmplifyUserPool(state.userPool.name);
  }
});

// Log when user data changes
_useUserStore.subscribe(logUserDataChanges);

// Set Sentry user based on user data
_useUserStore.subscribe(({ auth }) => {
  if ('user' in auth) {
    const { id, email, type } = auth.user;
    setSentryUser({ id, email, segment: type });
  } else {
    setSentryUser(null);
  }
});

// Update LocalStorage when user id or pool changes
_useUserStore.subscribe((state) => {
  // Wait to update until we know user state and don't persist stale state
  if (state.auth.status === 'CONFIGURING' || state.isStale) return;

  const latestStateToStore = getStateToStore(state);
  const storedState = userStateStore.getItem();

  if (!deepEqualWithJsonStringify(latestStateToStore, storedState)) {
    userStateStore.setItem(latestStateToStore);
    // console.log('Updated user state in LocalStorage', JSON.stringify(latestStateToStore));
  }
});

//
// User session initialization for current page
//

// Configure Amplify to use the same user pool that the user used last page load
// to keep the user signed-in if their refresh token is still valid.
configureAmplifyUserPool(_useUserStore.getState().userPool.name);

// Set initial user state based on session (in LocalStorage) unless we're on the OAuth
// callback page, in which case we should stay in a loading state until Amplify has
// verified the oauth parameters (code & state)
if (!isProcessingOauthCallbackParameters()) {
  // Wait for hydration to complete since SSG is unaware of user state
  onClientHydrationComplete(() => syncUserDataWithSession('pageload'));
} else {
  console.log('Processing OAuth /callback params');
}

// Keep user data in sync with current session (JWT ID token). Check session every time
// a new auth-related event occurs to ensure our state is up-to-date.
Hub.listen('auth', ({ payload }) => {
  mostRecentHubAuthEvent = { name: payload.event, timestamp: Date.now() };
  console.log(payload, new Date()); // TEMP: for debugging, remove once this is stable in prod a few weeks
  syncUserDataWithSession(payload);

  // Tell user why SSO sign-in failed
  if (payload.event === 'signInWithRedirect_failure') {
    showSignInFailedNotification(
      _useUserStore.getState().userPool.name,
      payload.data.error ? payload.data.error.message : undefined
    );
  }
});

// Debug user session issues in any environment
if (typeof window !== 'undefined') {
  Object.assign(window, {
    debugUser: {
      _useUserStore,
      syncUserDataWithSession,
      requestUserDataRefresh,
      setUserPool,
    },
  });
}

//
// Utils
//

// Fetch user's current session. Will reject if user is signed-out or if session can't be
// fetched within a reasonable amount of time. Typically resolves/rejects instantly since
// session can be fetched from LocalStorage
export function fetchUserSessionOrTimeout() {
  // After an OAuth sign-in fails, Amplify Hub fires a "signInWithRedirect_failure" event, and the
  // subsequent call to fetchUserSession() will hang forever and never resolve or reject :(
  // In this case, abort sooner to avoid making the user wait unnecessarily
  const didOAuthSignInJustFail = Boolean(
    mostRecentHubAuthEvent &&
      mostRecentHubAuthEvent.name === 'signInWithRedirect_failure' &&
      Date.now() - mostRecentHubAuthEvent.timestamp < 1000
  );

  // The longest I (Micah) have seen a signed-in session take to fetch (in Sentry) was 4 seconds
  const timeoutMs = didOAuthSignInJustFail ? 2000 : 4000;

  // If a session fetch is timed out and completes at a later time, log a warning in the console
  // so we're at least aware of it
  let onSessionFetched = waitForTimers([timeoutMs], () => {
    // At this point, session fetch should have timed out
    const timedOutTimestamp = Date.now();
    onSessionFetched = () => {
      const ellapsedTime = Date.now() - timedOutTimestamp;
      console.warn(
        `fetchUserSession() completed ${ellapsedTime}ms after its ${timeoutMs}ms timeout`
      );
    };
  });

  return pTimeout(
    fetchUserSession().finally(() => onSessionFetched()),
    { milliseconds: timeoutMs }
  );
}

// Get subset of user state to store in LocalStorage
function getStateToStore(state: UserStoreState): StoredUserState {
  return {
    userPool: state.userPool.hasUserChosenPool ? state.userPool.name : null,
    userId: 'user' in state.auth ? state.auth.user.id : null,
  };
}

// Is this the OAuth callback page and are there OAuth parameters (e.g. code & state) in the URL?
function isProcessingOauthCallbackParameters() {
  return Boolean(
    typeof window !== 'undefined' &&
      location.pathname === '/callback/' &&
      location.search.includes('code=')
  );
}

// For debugging issues that are hard to reproduce (e.g. why is page in this state?)
exposeOnWindow({ _useUserStore });
