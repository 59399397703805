//
// Suppliers with unique portal experiences
//

export const ultiumSupplierId = import.meta.env.VITE_ULTIUM_SUPPLIER_ID;
export const panasonicSupplierId = import.meta.env.VITE_PANASONIC_SUPPLIER_ID;
export const bmwGroupSupplierId = import.meta.env.VITE_BMW_GROUP_SUPPLIER_ID;
export const porscheSupplierId = import.meta.env.VITE_PORSCHE_SUPPLIER_ID;

// Cell manufacturer shipment requests should be approved by logistics, not bizdev
export const supplierIdsWithShipmentsApprovedByLogistics = [ultiumSupplierId, panasonicSupplierId];
