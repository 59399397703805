import { type FetchAuthSessionOptions } from '@aws-amplify/core';
import { fetchAuthSession } from 'aws-amplify/auth';

// A thin wrapper around Amplify's fetchAuthSession()
// Allows auth session to be overridden during end-to-end testing
export async function fetchUserSession(options?: FetchAuthSessionOptions) {
  return getMockedAuthSession() || fetchAuthSession(options);
}

// When end-to-end testing, mock user's session ID token so we can
// test how app behaves when user is signed-in
function getMockedAuthSession() {
  if (
    typeof window !== 'undefined' &&
    window.__endToEndTesting &&
    window.__endToEndTesting.fetchAuthSession
  ) {
    return window.__endToEndTesting.fetchAuthSession();
  }
}
