import { useIsUserStateStale } from '@/auth/user-store';
import { ForceUserRefreshDialog } from '@/components/auth/force-user-refresh-dialog';
import { Card } from '@/components/card';
import { SiteFooter } from '@/components/footer';
import Nav from '@/components/nav';
import { VpnPrompt } from '@/components/vpn-prompt';
import { pageContentClassName } from '@/utils/class-names';
import { isLocalDevelopment } from '@/utils/local-development';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { SWRConfig } from 'swr';

// Make sure CSS is loaded & Sentry is listening for errors
import '@/index.scss';
import '@/utils/sentry';

// The <Layout /> component wraps every page
// vike-react enforces <StrictMode> and adds a PageContextProvider
export default function Layout({ children }: { children: React.ReactNode }) {
  const isUserStateStale = useIsUserStateStale();

  // Don't re-fetch API requests when user state is stale (e.g. they signed-out in different tab)
  const swrConfigValue = {
    revalidateOnFocus: !isUserStateStale,
    revalidateOnReconnect: !isUserStateStale,
  };

  // In some environments, we require users to be on the Redwood Network to access the API
  const shouldShowVpnPrompt = import.meta.env.VITE_IS_REDWOOD_NETWORK_REQUIRED === 'true';

  return (
    <>
      <SWRConfig value={swrConfigValue}>
        <Nav />
        <ErrorBoundary
          // Sentry dialog will be shown unless it's blocked by browser extension or we're developing locally
          showDialog={!isLocalDevelopment()}
          fallback={
            <main className={pageContentClassName}>
              <Card heading="Unexpected Error">
                <p className="mt-1">
                  Sorry, this page broke unexpectedly. Please refresh or contact support.
                </p>
              </Card>
            </main>
          }
        >
          {children}
        </ErrorBoundary>
        <SiteFooter />
        <Toaster />
        {shouldShowVpnPrompt && <VpnPrompt />}
        {isUserStateStale && <ForceUserRefreshDialog />}
      </SWRConfig>
      <PlausibleAnalyticsScript />
    </>
  );
}

// Privacy-friendly analytics: https://plausible.io
function PlausibleAnalyticsScript() {
  return import.meta.env.MODE === 'production' ? (
    <script
      async
      defer
      data-domain="portal.redwoodmaterials.com"
      data-api="/plsible/api/event"
      src="/plsible/js/script.js"
    ></script>
  ) : null;
}
