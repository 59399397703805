import { isLocalDevelopment } from '@/utils/local-development';
import { type CognitoUserPoolConfig } from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';

//
// Keep track of the user pool associated with the current user
// Defaults to external. Updated whenever a user attempts sign-in
//
export const userPools = ['external', 'redwood_okta', 'vwgoa_sso', 'bmw_group', 'porsche'] as const;
export type UserPool = (typeof userPools)[number];

export const emailPasswordUserPools = ['external', 'bmw_group', 'porsche'] satisfies UserPool[];

const baseUrl =
  typeof window === 'undefined'
    ? '?'
    : `${isLocalDevelopment() ? 'http:' : 'https:'}//${location.host}`;

// Cognito user pool config for external (non-redwood) users authenticated
// via email + password
export const externalAwsAuthConfig = {
  userPoolId: import.meta.env.VITE_EXTERNAL_USER_POOL_ID,
  userPoolClientId: import.meta.env.VITE_EXTERNAL_USER_POOL_WEB_CLIENT_ID,
  passwordFormat: {
    minLength: 10,
  },
} satisfies CognitoUserPoolConfig;

// Cognito user pool config for Redwood Materials employees authenticated via Okta
const redwoodOktaAwsAuthConfig = {
  userPoolId: import.meta.env.VITE_RW_OKTA_USER_POOL_ID,
  userPoolClientId: import.meta.env.VITE_RW_OKTA_USER_POOL_WEB_CLIENT_ID,
  loginWith: {
    oauth: {
      domain: import.meta.env.VITE_RW_OKTA_OAUTH_DOMAIN,
      scopes: ['openid'],
      redirectSignIn: [`${baseUrl}/callback/`],
      redirectSignOut: [`${baseUrl}/`],
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
} satisfies CognitoUserPoolConfig;

// Cognito user pool config for VWGoA employees & VW/Audi dealers authenticated via
// various SSO providers
// Note: this is ONLY setup in UAT & Prod environments
const vwgoaSsoAwsAuthConfig = {
  userPoolId: import.meta.env.VITE_VWGOA_USER_POOL_ID,
  userPoolClientId: import.meta.env.VITE_VWGOA_USER_POOL_WEB_CLIENT_ID,
  loginWith: {
    oauth: {
      domain: import.meta.env.VITE_VWGOA_OAUTH_DOMAIN,
      scopes: ['openid'],
      redirectSignIn: [`${baseUrl}/callback/`],
      redirectSignOut: [`${baseUrl}/vwgoa/`],
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
} satisfies CognitoUserPoolConfig;

// VWGoA user pool has 3 SSO identity providers
export const vwgoaIdpProviders = {
  corporate: 'vwgoaCorporate',
  vwDealer: 'vwgoaVwDealer',
  audiDealer: 'vwgoaAudiDealer',
} as const;
export type VwgoaIdpProvider = (typeof vwgoaIdpProviders)[keyof typeof vwgoaIdpProviders];

// Cognito user pool config for BMW Group users authenticated via email + password
export const bmwGroupAwsAuthConfig = {
  userPoolId: import.meta.env.VITE_BMW_GROUP_USER_POOL_ID,
  userPoolClientId: import.meta.env.VITE_BMW_GROUP_USER_POOL_WEB_CLIENT_ID,
  passwordFormat: {
    minLength: 12,
  },
} satisfies CognitoUserPoolConfig;

// Cognito user pool config for Porsche users authenticated via email + password
export const porscheAwsAuthConfig = {
  userPoolId: import.meta.env.VITE_PORSCHE_USER_POOL_ID,
  userPoolClientId: import.meta.env.VITE_PORSCHE_USER_POOL_WEB_CLIENT_ID,
  passwordFormat: {
    minLength: 12,
  },
} satisfies CognitoUserPoolConfig;

export const userPoolConfigs = {
  external: externalAwsAuthConfig,
  redwood_okta: redwoodOktaAwsAuthConfig,
  vwgoa_sso: vwgoaSsoAwsAuthConfig,
  bmw_group: bmwGroupAwsAuthConfig,
  porsche: porscheAwsAuthConfig,
} satisfies Record<UserPool, CognitoUserPoolConfig>;

// Tell Amplify library what user pool to attempt to sign user into and to use to verify & refresh user's JWT
// Needs to be called once initially and then again if the user's preferred pool changes
export function configureAmplifyUserPool(userPool: UserPool) {
  // console.log('Configure amplify user pool', userPool);
  Amplify.configure({ Auth: { Cognito: userPoolConfigs[userPool] } });
}
