import { classNames } from '@/utils/class-names';
import { isLocalDevelopment } from '@/utils/local-development';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid/index.js';
import { ErrorBoundary } from '@sentry/react';
import { Fragment } from 'react';
import { Button } from './button';
import { Card } from './card';

type ModalProps = {
  isOpen: boolean;
  onClose: (reason: 'escape' | 'click_outside' | 'unhandled_exception' | 'x_button_click') => void;
  title: string | React.ReactNode;
  children: React.ReactNode;
  panelClassName?: string;
  topIcon?: React.ReactNode;
  includeCloseButton?: boolean;
};

export function Modal({
  isOpen,
  onClose,
  title,
  children,
  panelClassName = '',
  topIcon,
  includeCloseButton = true,
}: ModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose(window.event instanceof KeyboardEvent ? 'escape' : 'click_outside');
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6',
                  panelClassName
                )}
              >
                {/* Add error boundary around modal contents so any breakage doesn't leak to parent page */}
                <ErrorBoundary
                  // Sentry dialog will be shown unless it's blocked by browser extension or we're developing locally
                  showDialog={!isLocalDevelopment()}
                  fallback={
                    <Card heading="Unexpected Error" className="shadow-none">
                      <p className="mt-1">
                        Sorry, this modal broke unexpectedly. Please close it and re-open it or
                        contact support.
                      </p>
                      <Button
                        variant="white-outline"
                        onClick={() => onClose('unhandled_exception')}
                        className="mt-2"
                      >
                        Close
                      </Button>
                    </Card>
                  }
                >
                  <div>
                    {topIcon}
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    </div>
                  </div>
                  {includeCloseButton && (
                    <Button
                      title="Close"
                      onClick={() => onClose('x_button_click')}
                      variant="none"
                      className="absolute right-3 top-3 z-10 rounded-full p-1"
                    >
                      <XMarkIcon className="h-7 w-7" aria-hidden="true" />
                    </Button>
                  )}
                  {children}
                </ErrorBoundary>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
