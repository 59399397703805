import { parse } from 'chrono-node';
import { isEqual } from 'lodash-es';
import { memoizedIntlDateTimeFormat } from './intl';
import { ymdDayRegex } from './regex';
import { reportSentryError } from './sentry';

export const oneDayMs = 1000 * 60 * 60 * 24;
export const oneHourMs = 1000 * 60 * 60;
export const tenMinutesMs = 1000 * 60 * 10;
export const fiveMinutesMs = 1000 * 60 * 5;
export const twoMinutesMs = 1000 * 60 * 2;
export const oneMinuteMs = 1000 * 60 * 1;

// Convert date to yyyy-mm-dd (in Date's timezone)
// Source: https://stackoverflow.com/a/29774197/1546808
export function dateToYYYYMMDD(date: Date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * oneMinuteMs);
  return date.toISOString().split('T')[0];
}

// Convert yyyy-mm-dd to Date in user's timezone (12am, start of day)
export function yyyymmddToDate(ymd: string) {
  const d = new Date(ymd);
  return new Date(d.getTime() + d.getTimezoneOffset() * oneMinuteMs);
}

// Convert date to mm/dd/yyyy
export function dateToMMDDYYYY(date: Date) {
  const [y, m, d] = dateToYYYYMMDD(date).split('-');
  return [m, d, y].join('/');
}

export function getYesterdaysDate() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
}

export function getTomorrowsDate() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}

export function getFutureDate(daysAhead: number) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + daysAhead);
  return tomorrow;
}

export function getNextYear() {
  const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  return date.getFullYear();
}

export function diffDays(date1: Date, date2: Date) {
  return (date1.getTime() - date2.getTime()) / oneDayMs;
}

type Time = {
  hours: number;
  minutes: number;
};

// Attempt to derive two time objets from a time window string, e.g. "5 - 9pm"
// Make sure the first time is before the second time
// If validation fails for any reason, return undefined
// @todo -> write unit tests
export function getTimeWindow(timeString: string): [Time, Time] | undefined {
  const result = parseTimeString(timeString);
  if (!result) return;

  const { startTime, endTime } = result;
  if (startTime && endTime && isBefore(startTime, endTime)) return [startTime, endTime];
}

// Is start time before end time?
export function isBefore(startTime: Time, endTime: Time) {
  if (endTime.hours > startTime.hours) return true;
  if (endTime.hours === startTime.hours && endTime.minutes > startTime.minutes) return true;
  return false;
}

export function timeToHHMMSS(time: string | Time) {
  const { hours, minutes } = typeof time === 'string' ? parseTimeString(time)!.startTime! : time;
  return [hours, minutes, 0].map((num) => num.toString().padStart(2, '0')).join(':');
}

export function parseTimeString(timeString: string) {
  const [result] = parse(timeString);
  if (!result) return;

  const [startTime, endTime] = [result.start, result.end].map((parsedValue) => {
    if (!parsedValue) return;

    const hours = parsedValue.get('hour');
    const minutes = parsedValue.get('minute');
    if (typeof hours !== 'number' || typeof minutes !== 'number') return;

    return { hours, minutes };
  });

  // Ensure end time is after start time
  // May need fixed when am/pm is only specified for one of the two numbers
  if (startTime && endTime && result.end && !isBefore(startTime, endTime)) {
    if (
      result.start.isCertain('meridiem') &&
      !result.end.isCertain('meridiem') &&
      result.start.get('meridiem') === 0 &&
      result.end.get('meridiem') === 0
    ) {
      // e.g. 10am - 5
      // console.log('Assume end time is pm');
      endTime.hours += 12;
    } else if (
      !result.start.isCertain('meridiem') &&
      result.end.isCertain('meridiem') &&
      result.start.get('meridiem') === 1 &&
      result.end.get('meridiem') === 1
    ) {
      // e.g. 10 - 5pm
      // console.log('Assume start time is am');
      startTime.hours -= 12;
    } else {
      // End time is before start time
      return;
    }
  }

  return { startTime, endTime };
}

export type UtcOffset = `${'+' | '-'}${number}:${number}`;

// Convert "4 - 6pm" to two ISO datetime strings for API, e.g.
// "2023-10-09T16:00:00-07:00" and "2023-10-09T18:00:00-07:00"
// We include a UTC timezone offset explicitly, e.g. "-07:00" so that
// it's clear what the user's timezone is, and so that we can generate
// a time window string API-side for  emails
export function timeWindowToIsoDateStrings(
  timeWindow: string,
  dateYMD: string,
  utcTimezoneOffset: UtcOffset
): [string, string] {
  return getTimeWindow(timeWindow)!
    .map((timeObject) => timeToHHMMSS(timeObject))
    .map((hhmmss) => `${dateYMD}T${hhmmss}${utcTimezoneOffset}`) as [string, string];
}

// Convert two ISO datetime strings from API to "4 - 6pm" time window string
export function isoDateStringsToTimeWindow(
  isoDateStrings: [string, string],
  dateYMD: string,
  utcTimezoneOffset: UtcOffset
) {
  // Expect an ISO Date string that we have constructed with an explicit UTC offset using the above
  // function (does not end with Z)
  if (isoDateStrings.some((str) => !str.startsWith(dateYMD) || !str.endsWith(utcTimezoneOffset))) {
    throw new Error("isoDateStrings don't match date & timezone");
  }
  const timeWindowString = isoDateStrings
    .map((dateString) => {
      // eslint-disable-next-line prefer-const
      let [hours24, minutes] = dateString
        .split('T')[1]
        .split(':')
        .map((str) => parseInt(str));
      const [hours, amPm] = hoursToAmPm(hours24);
      return `${minutes ? [hours, minutes].join(':') : hours} ${amPm}`;
    })
    .join(' - ');

  // Sanity check to ensure constructed string exactly matches timestamps
  const generatedIsoDateStrings = timeWindowToIsoDateStrings(
    timeWindowString,
    dateYMD,
    utcTimezoneOffset
  );
  if (!isEqual(isoDateStrings, generatedIsoDateStrings)) {
    console.log({
      isoDateStrings,
      timeWindowString,
      dateYMD,
      utcTimezoneOffset,
      generatedIsoDateStrings,
    });
    throw new Error('Failed to convert isoDateStrings to time window');
  }

  return timeWindowString;
}

// Convert ISO date string to human readable date in user's current timezone
export const isoToHumanReadableDateTime = safelyFormatDate((isoDateString: string) => {
  // Sanity check (don't accept yyyy-mm-dd dates as we must know timezone and time)
  if (!isoDateString.includes('T')) throw new Error(`Invalid ISO Date string: ${isoDateString}`);

  return memoizedIntlDateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(new Date(isoDateString))
    .replace(/ AM$/, ' am')
    .replace(/ PM$/, ' pm');
});

// Convert yyyy-mm-dd date to a human-readable date string
export const ymdToHumanReadableDate = safelyFormatDate((ymdDateString: string) => {
  if (!ymdDayRegex.test(ymdDateString))
    throw new Error(`Invalid yyyy-mm-dd Date string: ${ymdDateString}`);

  return memoizedIntlDateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC', // Necessary to ensure day matches yyyy-mm-dd exactly regardless of user's timezone
  }).format(new Date(ymdDateString));
});

// Convert ISO date to a human-readable date string
export const isoToHumanReadableDate = safelyFormatDate(
  (isoDateString: string, timeZone?: string) => {
    if (!isoDateString.includes('T')) throw new Error(`Invalid ISO Date string: ${isoDateString}`);

    return memoizedIntlDateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone,
    }).format(new Date(isoDateString));
  }
);

export function ymdOrIsoToHumanReadableDate(dateString: string) {
  if (ymdDayRegex.test(dateString)) return ymdToHumanReadableDate(dateString);
  return isoToHumanReadableDateTime(dateString);
}

// ISO date string to yyyy-mm-dd in a particular timezone
export const isoToYmdInTimezone = safelyFormatDate((isoDateString: string, timeZone: string) => {
  if (!isoDateString.includes('T')) throw new Error(`Invalid ISO Date string: ${isoDateString}`);

  return memoizedIntlDateTimeFormat('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone,
  }).format(new Date(isoDateString));
});

// Format date with fallback if anything goes awry
// to avoid throwing error and breaking React UI
function safelyFormatDate<T>(formatter: (dateString: string, ...extraArgs: T[]) => string) {
  return function (dateString: string, ...extraArgs: T[]) {
    try {
      return formatter(dateString, ...extraArgs);
    } catch (error) {
      // Report formatting error in Sentry (typically happens in API sends us a date format we don't expect)
      reportSentryError(new Error(`Date formatting failed for "${dateString}"`), {
        cause: error,
        dateString,
      });
      // Fallback to showing un-formatted date in UI
      return dateString;
    }
  };
}

export function hoursToAmPm(hours: number): [number, 'am' | 'pm'] {
  let amPm: 'am' | 'pm' = 'am';
  if (hours > 12) {
    hours -= 12;
    amPm = 'pm';
  } else if (hours === 12) {
    amPm = 'pm';
  }
  return [hours, amPm];
}
