export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

// Typescript workaround for lookup up arbitary value in constant array
// See https://fettblog.eu/typescript-array-includes/
export function includes<T extends U, U>(arr: ReadonlyArray<T>, el: U): el is T {
  return arr.includes(el as T);
}

export type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T; // from lodash

// Typescript replacement for <Array>.filter(Boolean) that clarifies that resulting
// array items are all truthy. Usage: <Array>.filter(truthy)
// Source: https://stackoverflow.com/a/58110124/1546808
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export const objectKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

// Get all possible keys of a Union type
export type KeysOfUnion<T> = T extends T ? keyof T : never;

// Check if a value is a key of an object
export function isObjectKey<T extends object>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
}

export type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

// Typed Object.fromEntries()
export const objectFromEntries = <const T extends ReadonlyArray<readonly [PropertyKey, unknown]>>(
  entries: T
): { [K in T[number] as K[0]]: K[1] } => {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] };
};

// Helpful for adding to list of strings that you want autocompleted, e.g. 'A' | 'B' | AnyOtherString
// See https://x.com/mattpocockuk/status/1822917967316676787
// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyOtherString = string & {};

// For seeing nice type in your editor while debugging
// See https://x.com/mattpocockuk/status/1653403198885904387
export type Prettify<T> = {
  [K in keyof T]: T[K];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};
