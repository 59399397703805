import { get, round } from 'lodash-es';
import { z, ZodError } from 'zod';

//
// requestApi() utility is an opinionated wrapper around fetch() for making
// API requests (for JSON or text responses).
//
// Main features:
// - Validate response bodies using Zod schemas or custom validation functions to expose
//   type-safe responses to the rest of the codebase.
// - Report unexpected issues (e.g. not-OK responses, schema validation errors) to error monitoring
//   platform (e.g. Sentry) but do NOT report expected issues (e.g. request aborted, network error).
//   Expected issues may be logged but are not reported to avoid noise in error monitoring platform.
//   By automatically handling logging/reporting at this level, we can keep the rest of the codebase
//   simpler and focused on business logic.
//
// Minor features:
// - Timeout handling (e.g. abort after 60s)
// - Optionally handle not-OK responses by providing a fallback value
// - Always reject with RequestError() instance which exposes .cause and .responseBody (optional) for
//   debugging and handling particular API error codes. The RequestError.message is user-friendly and
//   can be shown in the UI.
//
// Assumptions:
// - API response bodies are expected to be JSON by default, falling back to text if JSON parsing fails.
//   There's no support for other response types (e.g. Blobs)
// - Zod is expected to be used for schema validation, but custom validation functions are also supported.
//

type RequestApiParams<T, U> = {
  url: string | URL;
  method?: 'GET' | 'POST' | 'HEAD' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: Record<string, string>;
  body?: BodyInit;
  abortSignal?: AbortSignal;
  // Optionally abort request after a max duration (ms)
  timeout?: { maxWaitMs: number; reportLevel: ReportLevel } | null;
  // Return valid response body (for type inference) or throw error. Use `ifFailValidation` to determine
  // on what to do if validation fails.
  validate?: (response: unknown, status: number) => T;
  // How to handle an Ok response body failing validation. Should we reject with an error
  // or resolve with the (invalid) response body? What level of error should we report in Sentry?
  ifFailValidation?: {
    shouldReject: boolean | { zod: boolean; custom: boolean };
    reportLevel: ReportLevel | { zod: ReportLevel; custom: ReportLevel };
  };
  // In certain cases we expect an error (e.g. 404, 400, etc.) and don't want to report it in Sentry
  // Allow caller to handle specific status codes / responses and return a fallback value instead
  // of rejecting and reporting an error. Return `undefined` to reject and report as usual.
  handleNotOkResponse?: (
    status: number,
    responseJSON: unknown,
    responseText: string | undefined
  ) => U | undefined;
  // Report error / warning in Sentry. Kept generic to avoid explicit Sentry dependency
  reportIssue: ReportIssueFunction;
};

// Types for response body (JSON or text) and its parsing status
type ResponseBody =
  | { isJson: null; text: undefined; json: undefined } // No response yet (or unable to read it as text)
  | { isJson: false; text: string; json: undefined } // Text response, not JSON
  | { isJson: true; text: string; json: unknown }; // JSON response

// Types for error reporting function (e.g. Sentry.captureException() wrapper) used by requestApi()
// to report unexpected issues for follow-up and debugging.
type ReportLevel = 'error' | 'warning' | 'dont_report';
type ReportIssueFunction = (
  level: Omit<ReportLevel, 'dont_report'>,
  error: Error,
  details: {
    url: string;
    requestBody: BodyInit | undefined;
    duration: number; // In seconds, rounded to nearest 0.1s
    responseBody: ResponseBody;
    [key: string]: unknown;
  }
) => void;
type SetupRequestApiParams = {
  // Report error / warning in Sentry. Kept generic to avoid explicit Sentry dependency
  reportIssue: ReportIssueFunction;
};

// Expose requestApi() with `reportIssue` already configured to avoid having to pass it every time
export function setupRequestApi({ reportIssue }: SetupRequestApiParams) {
  type Params<T, U> = Omit<RequestApiParams<T, U>, 'reportIssue'>;

  // Unfortunately we must tell Typescript about all the possible overload combinations to produce
  // the correct return type for each case. Overload order matters (first match is used).

  // Overload for when both validate and handleNotOkResponse are provided
  function wrappedRequestApi<T, U>(
    params: Params<T, U> & {
      validate: NonNullable<Params<T, U>['validate']>;
      handleNotOkResponse: NonNullable<Params<T, U>['handleNotOkResponse']>;
    }
  ): Promise<T | U>;

  // Overload for when only validate is provided (most common case)
  function wrappedRequestApi<T, U = never>(
    params: Params<T, U> & {
      validate: NonNullable<Params<T, U>['validate']>;
    }
  ): Promise<T>;

  // Overload for when only handleNotOkResponse is provided
  function wrappedRequestApi<T, U>(
    params: Params<T, U> & {
      handleNotOkResponse: NonNullable<Params<T, U>['handleNotOkResponse']>;
    }
  ): Promise<U | unknown>;

  // Overload for when neither validate nor handleNotOkResponse is provided
  function wrappedRequestApi<T = unknown, U = never>(params: Params<T, U>): Promise<T>;

  function wrappedRequestApi<T, U>(args: Params<T, U>): Promise<T | U | unknown> {
    return requestApi({ ...args, reportIssue });
  }

  return wrappedRequestApi;
}

export async function requestApi<T, U>({
  url,
  method = 'GET',
  headers,
  body,
  validate,
  abortSignal,
  timeout = { maxWaitMs: 60 * 1000, reportLevel: 'warning' },
  ifFailValidation = { shouldReject: { zod: false, custom: true }, reportLevel: 'error' },
  handleNotOkResponse,
  reportIssue: _reportIssue,
}: RequestApiParams<T, U>): Promise<T | U | unknown> {
  if (typeof url === 'string') url = new URL(url); // Convert URL string -> object for consistency
  const requestName = `${method} ${url.pathname}`; // For logging and reporting

  // Setup signal to abort if request takes too long
  let timeoutSignal: AbortSignal | undefined;
  if (timeout) {
    timeoutSignal = crossBrowserAbortSignalTimeout(timeout.maxWaitMs);
    abortSignal = abortSignal ? anySignal([abortSignal, timeoutSignal]) : timeoutSignal;
  }

  let responseBody: ResponseBody = { isJson: null, text: undefined, json: undefined };

  // Make request
  let res: Response;
  const startTime = Date.now();
  try {
    res = await fetch(url, { method, headers, body, signal: abortSignal });
  } catch (fetchError) {
    // Fetch errors are expected due to network/connectivity issues, CORS, timeouts, etc.
    // We log them and reject, but typically don't report them in Sentry to avoid noise.
    const { errorMessage, context } = handleFetchError(fetchError as Error);
    throw new RequestError(errorMessage, { reason: errorMessage, ...context }, undefined);
  }

  // Get response body as JSON / text
  try {
    // Get text (consuming stream), then try to parse as JSON
    const text = await res.text();
    responseBody = { isJson: false, text, json: undefined };
    const json = JSON.parse(text);
    responseBody = { isJson: true, text, json };
  } catch (err1) {
    /* empty */
  }
  const responseJsonOrText = responseBody.isJson ? responseBody.json : responseBody.text;

  // Handle not-OK responses (status < 200 or status > 299)
  if (!res.ok) {
    // Allow caller to provide a fallback value to resolve with
    if (handleNotOkResponse) {
      const result = handleNotOkResponse(res.status, responseBody.json, responseBody.text);
      if (result !== undefined) return result satisfies U;
    }

    // No fallback provided: report a warning and reject the Promise
    const reason = `Not-OK status code ${res.status}`;
    report('warning', new Error(reason));

    const errorMessageForUser = get(responseBody.json, 'error.message')
      ? `${get(responseBody.json, 'error.message')} (${res.status} Error)`
      : `${res.status} Error`;
    throw new RequestError(errorMessageForUser, { reason }, responseBody);
  }

  // If caller hasn't provided a validation function, just return parsed response body (no type inference possible)
  if (!validate) return responseJsonOrText satisfies unknown;

  // Validate response
  let validationError: unknown;
  try {
    // Return typed response body if it passes validation
    return validate(responseJsonOrText, res.status) satisfies T;
  } catch (error) {
    validationError = error;
  }

  // Handle empty response (likely a network issue)
  if (!responseBody.text) {
    const reason = `Unexpected empty response`;
    report('warning', new Error(reason));

    let errorMessageForUser = 'No response';
    if (isOnline() === false) errorMessageForUser += '. Please check your internet connection.';
    throw new RequestError(errorMessageForUser, { reason }, responseBody);
  }

  // Handle Zod validation error(s)
  if (validationError instanceof ZodError) {
    const { issues } = validationError;
    const issueSummary = `${issues.length} issue(s): ${issues[0]?.path.join('.')}`;
    const cause = {
      reason: `Response failed Zod validation: ${issueSummary}`,
      // If we're requesting 1000 records and they all fail, don't send 1000 issues to Sentry, 10 is plenty.
      issues: issues.slice(0, 10),
    };
    const { reportLevel, shouldReject } = getValidationConfig('zod');
    report(reportLevel, new Error(cause.reason), cause);

    if (shouldReject) throw new RequestError('Unexpected response', cause, responseBody);

    // Return response as if it passed validation, since shouldReject is set to false.
    // This is NOT type-safe, but we do this by default on Zod validation errors since Zod validation is strict
    // and in most cases the response is still usable and won't break the UI.
    return responseJsonOrText satisfies unknown as T;
  }

  // Handle custom validation error
  {
    const cause = {
      reason: `Response failed validation: ${validationError}`,
      validationError,
    };
    const { reportLevel, shouldReject } = getValidationConfig('custom');
    report(reportLevel, new Error(cause.reason), cause);

    if (shouldReject) throw new RequestError('Unexpected response', cause, responseBody);

    // Return response as if it passed validation, since shouldReject is set to false.
    // This is NOT type-safe, and is NOT recommended for custom validation
    return responseJsonOrText satisfies unknown as T;
  }

  // Handle fetch() rejecting with an error (e.g. network issue, CORS issue, timeout, etc.)
  function handleFetchError(fetchError: Error) {
    // Log request failures due to CORS issues or network issues, but don't report them
    // b/c they are inevitable and expected. Logs will still show up in Sentry breadcrumbs
    // to help debug any downstream errors
    const context = {
      status: '(none)',
      url: url.toString(),
      requestBody: body,
      duration: round((Date.now() - startTime) / 1000, 1),
      fetchError,
      wasAborted: Boolean(fetchError && (fetchError as Error).name === 'AbortError'),
      didTimeOut: Boolean(timeoutSignal?.aborted),
      isOnline: isOnline(),
    };

    let errorMessage: string;
    if (context.didTimeOut && timeout) {
      // Log or report timeout errors
      const { maxWaitMs } = timeout;
      const maxWaitText = maxWaitMs < 1000 ? `${maxWaitMs}ms` : `${round(maxWaitMs / 1000, 1)}s`;
      errorMessage = `Request timed out after ${maxWaitText}`;
      if (timeout.reportLevel === 'dont_report') {
        console.warn(`${errorMessage}: ${requestName}`, context);
      } else {
        report(timeout.reportLevel, new Error(errorMessage), context);
      }
    } else if (context.wasAborted) {
      // Ignore aborted requests entirely, as this may be done programmatically with
      // AbortController when we no longer need a response, or on user page navigation, etc.
      errorMessage = 'Request aborted';
    } else {
      // Log warning if request fails for any other reason (e.g. CORS, network issue)
      console.warn(`Request failed: ${requestName}`, context);
      errorMessage = 'Request failed';
    }

    // If user is offline, prompt them to check their internet connection
    if (context.isOnline === false) {
      errorMessage += '. Please check your internet connection.';
    }

    return { errorMessage, context };
  }

  // Call _reportIssue() with more context about the request
  function report(level: ReportLevel, error: Error, details?: { [key: string]: unknown }) {
    if (level === 'dont_report') return;

    // Differentiate errors by method and URL so Sentry can group them by endpoint
    const reason = error.message;
    error.message += `: ${requestName}`;

    _reportIssue(level, error, {
      reason,
      ...details,
      // Include request details
      url: url.toString(),
      requestBody: body,
      duration: round((Date.now() - startTime) / 1000, 1),
      // Include response from server
      responseBody,
      responseBodyLength: responseBody.text?.length,
      // Include Content-Length to help debug empty responses
      contentLength: res?.headers.get('Content-Length'),
    });
  }

  function getValidationConfig(key: 'zod' | 'custom') {
    const { shouldReject, reportLevel } = ifFailValidation;
    return {
      shouldReject: typeof shouldReject === 'object' ? shouldReject[key] : shouldReject,
      reportLevel: typeof reportLevel === 'object' ? reportLevel[key] : reportLevel,
    };
  }
}

const apiErrorSchema = z.object({
  error: z.object({
    code: z.string(),
    message: z.string().optional(),
    id: z.string().optional(),
  }),
});
type ApiResponseErrorSchema = z.infer<typeof apiErrorSchema>['error'];

function getApiError(responseBody?: ResponseBody): ApiResponseErrorSchema | undefined {
  try {
    return apiErrorSchema.parse(responseBody?.json).error;
  } catch (err) {
    return undefined;
  }
}

// Error subclass thrown by requestApi() when API request fails for any reason
// Exposes responseBody and cause for debugging and handling particular API error codes
export class RequestError extends Error {
  cause: RequestErrorCause;
  responseBody: ResponseBody | undefined;

  constructor(
    message: string, // Shown to user in UI
    cause: RequestErrorCause,
    responseBody: ResponseBody | undefined
  ) {
    super(message);
    Object.setPrototypeOf(this, RequestError.prototype); // For Typescript
    this.name = 'RequestError';

    // Augment cause with API error code and message if possible
    this.cause = { ...cause, apiError: getApiError(responseBody) };

    // Expose response body for debugging if available
    this.responseBody = responseBody;
  }
}

type RequestErrorCause = {
  reason: string; // Reason that requestApi() rejected
  apiError?: ApiResponseErrorSchema; // Error response from API (code, message, id)
  [key: string]: unknown; // Additional context for debugging
};

// AbortSignal.timeout() w/ fallback for older browsers
// https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout#browser_compatibility
export function crossBrowserAbortSignalTimeout(timeoutMs: number) {
  if (AbortSignal.timeout) return AbortSignal.timeout(timeoutMs);

  const abortController = new AbortController();
  setTimeout(() => {
    abortController.abort(new Error('Timeout'));
  }, timeoutMs);
  return abortController.signal;
}

// Create signal that is aborted when any of its input signals are aborted
// Source: https://github.com/whatwg/fetch/issues/905#issuecomment-1425708260
function anySignal(signals: AbortSignal[]): AbortSignal {
  const controller = new AbortController();

  for (const signal of signals) {
    if (signal.aborted) return signal;

    signal.addEventListener('abort', () => controller.abort(signal.reason), {
      signal: controller.signal,
    });
  }

  return controller.signal;
}

function isOnline(): boolean | null {
  try {
    return navigator.onLine;
  } catch (err) {
    return null;
  }
}
