import { getAuthHeader, requestApi, urlWithParams } from '@/utils/requests';
import { z } from 'zod';
import { baseUrl } from '../config';
import { components, paths } from '../cp-openapi-zod-export';

export type SupplierCognitoUser = z.infer<typeof components.schemas.SupplierCognitoUser>;
export const userRolesSchema = components.schemas.UserRoles;
export type UserRoles = z.infer<typeof userRolesSchema>;
export type CreateCognitoUserBody = z.infer<typeof components.schemas.CreateCognitoUserBody>;
export type UpdateCognitoUserBody = z.infer<typeof components.schemas.UpdateCognitoUserBody>;
export type SupplierUserPool = 'default' | 'bmw_group' | 'porsche';

async function _fetchSupplierUsersPage(userPool: SupplierUserPool, cursor: string | null) {
  const endpoint = '/redwood/supplier-users/{user_pool}';
  const urlBeforeParams = `${baseUrl}${endpoint.replace('{user_pool}', userPool)}`;
  const responseSchema = paths[endpoint]['get']['responses'][200]['content']['application/json'];
  const querySchema = paths[endpoint]['get']['parameters']['query'];
  const params: z.infer<typeof querySchema> = cursor ? { cursor } : undefined;
  const { data } = await requestApi({
    url: params ? urlWithParams(urlBeforeParams, params) : urlBeforeParams,
    headers: await getAuthHeader(),
    validate: (res) => responseSchema.parse(res),
  });
  return data;
}

export async function fetchSupplierUsers(
  userPool: SupplierUserPool,
  partialCallback: (users: SupplierCognitoUser[]) => void // Return results as they are fetched
) {
  const users: SupplierCognitoUser[] = [];
  let hasMorePages = true;
  let cursor: string | null = null;
  while (hasMorePages) {
    const pageResult = await _fetchSupplierUsersPage(userPool, cursor);
    users.push(...pageResult.users);
    partialCallback(sortUsers(users));
    cursor = pageResult.cursor;
    if (!cursor) hasMorePages = false;
  }
  return sortUsers(users);
}

function sortUsers(users: SupplierCognitoUser[]) {
  const sortKey = (user: SupplierCognitoUser) =>
    ['givenName', 'familyName', 'email']
      .map((key) => user.cognitoUserAttributes[key] || '')
      .join('')
      .replaceAll(' ', '');
  return users.sort((a, b) => (sortKey(a) > sortKey(b) ? 1 : -1));
}

export async function createSupplierUser(userPool: SupplierUserPool, body: CreateCognitoUserBody) {
  const endpoint = '/redwood/supplier-users/{user_pool}';
  const url = `${baseUrl}${endpoint.replace('{user_pool}', userPool)}`;
  const responseSchema = paths[endpoint]['post']['responses'][200]['content']['application/json'];
  const { data } = await requestApi({
    method: 'POST',
    url,
    headers: await getAuthHeader(),
    body: JSON.stringify(body),
    validate: (res) => responseSchema.parse(res),
  });
  return data;
}

export async function updateSupplierUser(userPool: SupplierUserPool, body: UpdateCognitoUserBody) {
  const endpoint = '/redwood/supplier-users/{user_pool}/{username}';
  const url = `${baseUrl}${endpoint
    .replace('{user_pool}', userPool)
    .replace('{username}', body.email)}`;
  const responseSchema = paths[endpoint]['patch']['responses'][200]['content']['application/json'];
  const { data } = await requestApi({
    method: 'PATCH',
    url,
    headers: await getAuthHeader(),
    body: JSON.stringify(body),
    validate: (res) => responseSchema.parse(res),
  });
  return data;
}

export async function deleteSupplierUser(userPool: SupplierUserPool, email: string) {
  const endpoint = '/redwood/supplier-users/{user_pool}/{username}';
  const url = `${baseUrl}${endpoint.replace('{user_pool}', userPool).replace('{username}', email)}`;
  const responseSchema = paths[endpoint]['delete']['responses'][200]['content']['application/json'];
  const { data } = await requestApi({
    method: 'DELETE',
    url,
    headers: await getAuthHeader(),
    validate: (res) => responseSchema.parse(res),
  });
  return data;
}

export async function sendTestEmail(email: string) {
  const endpoint = '/test-email';
  const responseSchema = paths[endpoint]['post']['responses'][200]['content']['application/json'];
  const bodySchema = paths[endpoint]['post']['requestBody']['content']['application/json'];
  const body: z.infer<typeof bodySchema> = { sendTo: email };
  const { data } = await requestApi({
    method: 'POST',
    url: `${baseUrl}${endpoint}`,
    headers: await getAuthHeader(),
    body: JSON.stringify(body),
    validate: (res) => responseSchema.parse(res),
  });
  return data;
}
