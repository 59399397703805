import { setRedirectRoutes } from '@/auth/redirect-user-after-oauth-callback';
import { setUserPool } from '@/auth/user-store';
import { signInWithRedirect } from 'aws-amplify/auth';

export function signInRedwoodUserWithOkta() {
  // Bring user back to this page after signing in
  setRedirectRoutes(location.pathname);

  // Ensure user pool is correct (reload not required due to redirect)
  setUserPool('redwood_okta', false);

  // Redirect user to Okta
  return signInWithRedirect({
    provider: { custom: import.meta.env.VITE_RW_OKTA_IDENTITY_PROVIDER_NAME },
  });
}

export function signInVwgoaUserWithSso(
  vwSsoProvider: string,
  afterSignInSuccessRedirect = location.pathname,
  afterSignInErrorRedirect = afterSignInSuccessRedirect
) {
  if (!import.meta.env.VITE_VWGOA_USER_POOL_ID) {
    alert('Sorry, this sign-in method is not supported in this environment');
    return Promise.reject('No VWGoA user pool');
  }

  // Specify where to send user after they sign-in (on OAuth /callback page)
  setRedirectRoutes(afterSignInSuccessRedirect, afterSignInErrorRedirect);

  // Ensure user pool is correct (reload not required due to redirect)
  setUserPool('vwgoa_sso', false);

  // Redirect user to one of VW's SSO providers
  return signInWithRedirect({ provider: { custom: vwSsoProvider } });
}
