import { exposeOnWindow } from '@/utils/local-development';

const renderStartTime = Date.now();

// Expose global to let client know when React Hydration has completed
exposeOnWindow({ __isPageHydrated: false });

export default function onHydrationEnd() {
  console.log(`Hydration completed in ${Date.now() - renderStartTime} ms`);
  if (typeof window !== 'undefined') {
    // Used for delaying hydration-dependent functionality (e.g. showing toasts, checking user state)
    // setTimeout(..., 0) is used to ensure first render has completed so we can muck w/ state w/out
    // causing a hydration mis-match
    setTimeout(() => {
      window.__isPageHydrated = true;
      window.dispatchEvent(new CustomEvent('HYDRATION_COMPLETE'));
    }, 0);
  }
}
