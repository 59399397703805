// VWGoA SAGA ID with optional spaces, e.g. "V0660 USA4440507392108 0120230401"
export const vwSagaIdRegex = /^[VA]\d{4}\s*USA[\dA-Z]{13,15}\s*\d{10}$/;

// VWGoA Dealer Code, e.g. 01A14
export const vwDealerCodeRegex = /^\d\d[A-Z\d]\d\d$/;

// 17-digit VIN, e.g. WA1CWBF1XPD009566
export const vinRegex = /^[A-Z0-9]{17}$/;

// Should match yyyy-mm-dd date (year 2000+) from browser's date input
// Adapted from https://stackoverflow.com/a/22061879/1546808
export const ymdDayRegex = /^20\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

// E.164 international phone number format
export const e164PhoneNumberRegex = /^\+[1-9]\d{4,14}$/;

// UTC timezone offset, e.g. +00:00, -07:00
export const utcTimezoneOffsetRegex = /^[+-](2[0-3]|[01][0-9]):[0-5][0-9]$/;

// Oracle ERP ID
export const erpIdRegex = /^300\d{12}$/;

// Shipment request ID
export const shipmentRequestIdRegex = /^sr_0\d{7}$/;

// BMW Location ID
export const bmwLocationIdRegex = /^[\dA-Z]{5}$/;

// Porsche Location ID
export const porscheLocationIdRegex = /^450\d{4}$/; // 7 integers, starting with 450
