import { Fragment } from 'react';
import { usePageContext } from 'vike-react/usePageContext';
import { EmailLink, Link } from './link';

export function SiteFooter() {
  const { urlPathname } = usePageContext();
  const supportEmails = useSupportEmails();

  return (
    <footer className="flex w-full flex-col items-center gap-4 py-4 text-center text-sm text-gray-500">
      {supportEmails && (
        <div>
          Need help? Email us at{' '}
          {supportEmails.map((email, index) => (
            <Fragment key={email}>
              {index > 0 && ' or '}
              <EmailLink email={email} className="text-gray-600" />
            </Fragment>
          ))}
        </div>
      )}
      <div className="max-w-5xl  sm:px-6 lg:px-8">
        © {new Date().getFullYear()} Redwood Materials Inc.&ensp;|&ensp;
        {urlPathname.startsWith('/sell-my-battery') && (
          <>
            <Link to="/sell-my-battery/terms-of-use/" target="_blank" className="text-gray-600">
              Terms&nbsp;of&nbsp;Use
            </Link>
            &ensp;|&ensp;
          </>
        )}
        <Link
          href="https://www.redwoodmaterials.com/privacy/"
          target="_blank"
          className="text-gray-600"
        >
          Privacy&nbsp;Policy
        </Link>
      </div>
    </footer>
  );
}

type RedwoodEmail = `${string}@redwoodmaterials.com`;

function useSupportEmails(): RedwoodEmail[] | null {
  const { urlPathname: path } = usePageContext();
  if (path.startsWith('/dashboard')) return null;
  if (path.startsWith('/vwgoa')) return ['vw@redwoodmaterials.com', 'audi@redwoodmaterials.com'];
  if (path.startsWith('/sell-my-battery')) return ['evbattery@redwoodmaterials.com'];
  return ['customerops@redwoodmaterials.com']; // Default for all other pages
}
